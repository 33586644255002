<template>
    <div>
        <LayoutMetaData
            :title="title"
            :meta="meta"
        />
        <LayoutContentRouter
            :content="pageData"
        />
    </div>
</template>
<script setup>
import { useHead, onMounted, useNuxtApp, useAnalytics } from '#imports';
import { usePageStore } from '~dsc/stores/cms/page';
import { useUserStore } from '~dsc/stores/cms/user';
import { storeToRefs } from 'pinia';
const { loggedIn } = storeToRefs(useUserStore());
const { pageTitle: title, meta, pageData, pageBuffer, slug } = storeToRefs(usePageStore());
const { $analytics } = useNuxtApp();
const { gtm } = useAnalytics();

useHead({
    title,
    bodyAttrs: {
        class: `page-${slug.value}`,
    },
});

onMounted(() => {
    try {
        const currentData = import.meta.client ? pageBuffer.value : pageData.value;
        const productBlocks = currentData
            .filter(block => block.contentType === 'cardList' && [...block.cards.flatMap(card => card.products)].filter(v => v).length > 0);

        if (productBlocks.length) {
            productBlocks.forEach(block => {
                if (block.display?.component === 'product') {
                    gtm().trackEvent({
                        event: 'view_item',
                        action: 'load',
                        item_list_id: block.id,
                        item_list_name: block.title && block.title !== '' ? block.title : block.internal,
                        value: [...block.cards.flatMap(card => card.products.map(product => $analytics.priceWithoutTax(product)))].reduce((a, b) => a + b),
                        items: [...block.cards.flatMap(card => card.products.map(product => $analytics.productToAnalytics(product)))],
                        event_function: 'general',
                        logged_in_status: loggedIn.value ? 'logged_in' : 'logged_out',
                        user_type: 'consumer',
                    });
                } else {
                    gtm().trackEvent({
                        event: 'view_item_list',
                        action: 'load',
                        item_list_id: block.id,
                        item_list_name: block.title && block.title !== '' ? block.title : block.internal,
                        items: [...block.cards.flatMap(card => card.products.map(product => $analytics.productToAnalytics(product)))],
                        event_function: 'general',
                        logged_in_status: loggedIn.value ? 'logged_in' : 'logged_out',
                        user_type: 'consumer',
                    });
                }
            });
        }
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.warn('Product Gtm failed');
        }
    }
});

</script>
